import dayjs from 'dayjs';

export const MoveOutSameLeaseEndDate = (blockMtm, days, noticeGiven) => {
  return (
    <div className="font-proxima text-sm">
      <div className=" border-b border-b-black w-full mb-6 mt-6"></div>

      <div>
        <span className="font-bold">
          {' '}
          If your move-out date is the same as your Lease End Date,
        </span>{' '}
        you are required to provide a <span className="font-bold">{days + ' '} days notice</span> of
        your intent to vacate per your Lease Agreement. You are providing a{' '}
        <span className="font-bold"> {noticeGiven + ' '} days notice.</span> You will be rent
        responsible through the required notice period. If the required notice period extends beyond
        your lease end date, you may be billed
        {blockMtm ? (
          <span>
            {' '}
            a hold-over rate. You can locate this rate in the section of your renewal letter that
            explains what rate applies if you do not execute a renewal or move out by your lease
            expiration date, subject to applicable law.
          </span>
        ) : (
          <span>
            {' '}
            the Month-to-Month (MTM) rate for the required notice days beyond your lease end date,
            subject to applicable law.
          </span>
        )}{' '}
        <br />
      </div>
      <div className="font-bold pt-[30px] pb-5">
        Please note that you are required to vacate the apartment home by the move out date you
        enter in this form.
      </div>
    </div>
  );
};

export const MoveOuteGraterLeaseEndDate = (
  blockMtm,
  days,
  noticeGiven,
  leaseTerm,
  leaseExpirationDate
) => {
  const showAlternateTex = (leaseTerm, leaseExpirationDate) => {
    const currentDateUtc = dayjs.utc().startOf('day');
    const leaseEndDateUtc = dayjs.utc(leaseExpirationDate).startOf('day');
    const currentLeaseTerm = 'Month-to-Month';
    const isLEDInPriorMonth = leaseEndDateUtc.isBefore(currentDateUtc, 'month');

    const isMTM = currentLeaseTerm === leaseTerm;

    return isLEDInPriorMonth || isMTM;
  };

  return (
    <div className="font-proxima text-sm ">
      <div className=" border-b border-b-black w-full mb-6 mt-6"></div>

      <div>
        {blockMtm ? (
          <>
            {!showAlternateTex(leaseTerm, leaseExpirationDate) && (
              <div>
                <span className="font-bold">
                  If your move-out date is after your Lease End Date
                </span>
                , you may be billed a hold-over rate. You can locate this rate in the section of
                your renewal letter that explains what rate applies if you do not execute a renewal
                or move out by your lease expiration date.
              </div>
            )}
            <div className="pt-6">
              You are required to provide a{' '}
              <span className="font-bold">{days + ' '} days notice </span> of your intent to vacate
              per your Lease Agreement. You are providing a{' '}
              <span className="font-bold">{noticeGiven + ' '} days notice.</span> You will be rent
              responsible through the required notice period.{' '}
              {!showAlternateTex(leaseTerm, leaseExpirationDate) && (
                <div style={{ display: 'inline' }}>
                  If the required notice period extends beyond your lease end date, you may be
                  billed the hold-over rate mentioned above for the required notice days beyond your
                  lease end date, subject to applicable law.
                </div>
              )}
            </div>
          </>
        ) : (
          <>
            {!showAlternateTex(leaseTerm, leaseExpirationDate) && (
              <div>
                <span className="font-bold">
                  If your move-out date is after your Lease End Date
                </span>
                , you will be billed the Month-to-Month (MTM) rate provided in your renewal offer
                for any days up to and including your move-out date.
              </div>
            )}
            <div className="pt-6">
              You are required to provide a{' '}
              <span className="font-bold">{days + ' '} days notice </span>
              of your intent to vacate per your Lease Agreement. You are providing a{' '}
              <span className="font-bold">{noticeGiven + ' '} days notice.</span> You will be rent
              responsible through the required notice period.{' '}
              {!showAlternateTex(leaseTerm, leaseExpirationDate) && (
                <div>
                  {' '}
                  If the required notice period extends beyond your lease end date, you may be
                  billed the Month-to-Month (MTM) rate for the required notice days beyond your
                  lease end date, subject to applicable law.
                </div>
              )}
            </div>
          </>
        )}
      </div>
      <div className="font-bold  pt-[30px] pb-5">
        Please note that you are required to vacate the apartment home by the move out date you
        enter in this form.
      </div>
    </div>
  );
};

export const RemainRentResponsible = () => {
  return (
    <div className="font-proxima ">
      <div className=" border-b border-b-black w-full mb-6 mt-6"></div>
      <p className="font-proxima pb-4  text-sm font-semibold mb-1 md:text-[24px] md:font-medium pt-5">
        Lease Termination Options
      </p>
      <span className="font-bold">Remain Rent Responsible</span> – After you submit this form, we
      will begin the process to market the apartment to re-rent. On the move-out date selected in
      this form, you must return keys, fobs and/or keycards and return the apartment back to us. You
      will remain liable for Rent and other charges through the earlier of your Lease End Date or
      until the date a new resident occupies and begins paying rent for your apartment. Subject to
      applicable law, you may be responsible for the rent differential through the Lease End Date if
      the apartment is re-rented at a lesser amount. Please refer to your lease agreement for any
      additional details.
    </div>
  );
};

export const DaysGuarantee = (date) => {
  return (
    <div className="font-proxima">
      <div className=" border-b border-b-black w-full mb-6 mt-6"></div>
      <div>
        <div className="pt-6">
          <span className="font-bold">30 Day Guarantee</span> – You are within the first 30 days of
          your initial lease term and can utilize your 30 day guarantee by submitting this form by{' '}
          {dayjs(date).format('MM/DD/YYYY')}.
        </div>
        <div className="pt-6">
          You must provide 30 days notice of your intent to vacate and vacate your apartment home by
          the end of your 30 day notice period. You must also pay all amounts due from the Lease
          Begin Date through the end of the 30 day notice period, or until you vacate your apartment
          home, whichever is later.
        </div>
        <div className="pt-[30px] pb-5">
          An AvalonBay community associate will reach out to you shortly to assist you with this
          process.
        </div>
      </div>
    </div>
  );
};

export const Transfer = () => {
  return (
    <div className="font-proxima leading-6 ">
      <div className=" border-b border-b-black w-full mb-6 mt-6"></div>
      We would love to assist you with the transfer process. Please reach out to your community team
      to assist you as additional charges may apply.
      <div className="pt-6">
        Learn about our Relocation Advantage Program
        <a
          href={'https://www.avaloncommunities.com/relocation-advantage/'}
          target="_blank"
          className="text-primary font-bold">
          {' '}
          here.
        </a>
      </div>
    </div>
  );
};

export const MoveOutDatePriorLeaseEndDate = () => {
  return (
    <div className="font-proxima">
      <div className=" mb-6 mt-6"></div>
      If your move-out date is prior to your Lease End Date, you may remain liable for Rent and
      other charges through the earlier of your Lease End Date or until the date a new resident
      occupies and begins paying rent for your apartment. You may also be liable for any rent
      damages we may incur. Please refer to your Lease Agreement for additional details if you want
      to end your lease early.
    </div>
  );
};
